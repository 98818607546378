/* ==========================================================================
 * Display
 * ========================================================================== */

/*
  Display

  Markup:
  <p class="h-hidden">This text is hidden.</p>

  Styleguide Helpers.Display
*/

.h-hidden {
  display: none;

  &--mobile {
    display: none;

    @media screen and (min-width: 720px) {
      display: inherit;
    }
  }

  &--larger-than-mobile {
    @media screen and (min-width: 720px) {
      display: none;
    }
  }
}

.h-match-viewport {
  height: 120vh !important;
  width: 100vw !important;
  top: -10vh !important;
  min-height: 768PX;
}

.h-center-margin {
  margin-left: auto;
  margin-right: auto;
}

.h-flex { display: flex; }
.h-narrow { max-width: 800PX !important; }
.h-more-narrow { max-width: 700PX !important; }
.h-very-narrow { max-width: 600PX !important; }
.h-most-narrow { max-width: 300PX !important; }

.h-images-top-margin img { margin-top: var(--spacing-lg); }

.h-margin-left-med { margin-left: var(--spacing-med) !important; }
.h-margin-left-lg { margin-left: var(--spacing-lg) !important; }
.h-margin-left-xl { margin-left: var(--spacing-xl) !important; }

.h-margin-right-med { margin-right: var(--spacing-med) !important; }
.h-margin-right-lg { margin-right: var(--spacing-lg) !important; }
.h-margin-right-xl { margin-right: var(--spacing-xl) !important; }

.h-margin-top-med { margin-top: var(--spacing-med) !important; }
.h-margin-top-lg { margin-top: var(--spacing-lg) !important; }
.h-margin-top-xl { margin-top: var(--spacing-xl) !important; }

.h-margin-bottom-med { margin-bottom: var(--spacing-med) !important; }
.h-margin-bottom-lg { margin-bottom: var(--spacing-lg) !important; }
.h-margin-bottom-xl { margin-bottom: var(--spacing-xl) !important; }

.h-padding-top-none { padding-top: 0 !important; }
.h-padding-bottom-none { padding-bottom: 0 !important; }

.h-cursor-pointer { cursor: pointer; }
.h-relative { position: relative; }

.grecaptcha-badge { background-color: #fff; }
