/* ==========================================================================
 * Input Groups
 * ========================================================================== */

/*
  Input Groups

  Markup:
  <div class="c-input-group">
    <input class="c-input" id="search" type="search">
    <button class="c-button">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
      </svg>
      <span>Search</span>
    </button>
  </div>

  Styleguide: Components.Input Groups
*/

.c-input-group {
  align-items: stretch;
  display: flex;

  .c-input {
    flex: 1;
  }

  .c-button {
    flex-shrink: 0;
  }

  .c-input,
  .c-button {
    border-radius: 0;

    &:first-child {
      border-bottom-left-radius: var(--border-radius-default);
      border-right: 0;
      border-top-left-radius: var(--border-radius-default);
    }

    &:last-child {
      border-bottom-right-radius: var(--border-radius-default);
      border-top-right-radius: var(--border-radius-default);
    }
  }
}
