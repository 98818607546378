/* ==========================================================================
 * Menu Icon
 * ========================================================================== */

/*
  Menu Icon

  Markup:
  <button class="c-menu-icon js-sg-slideout-toggle">
    <div class="c-menu-icon__icon">
      <span class="c-menu-icon__line"></span>
      <span class="c-menu-icon__line"></span>
      <span class="c-menu-icon__line"></span>
    </div>
  </button>

  Styleguide: Components.Menu Icon
*/

:root {
  --menu-icon-width: 18PX;
  --menu-icon-height: 18PX;
  --menu-icon-border-radius: 100px;
  --menu-icon-color: var(--theme-color-accent);
  --menu-icon-spacing: 4PX;
  --menu-icon-transition-easing: ease-in-out;
  --menu-icon-transition-timing: 0.15s;
}

.c-menu-icon {
  cursor: pointer;
  display: inline-block;
  margin: calc(var(--spacing-med) * -1);
  margin-right: 0;
  padding: var(--spacing-med);

  &__icon {
    height: var(--menu-icon-height);
    pointer-events: none;
    position: relative;
    width: var(--menu-icon-width);
  }

  &__line {
    background-color: var(--menu-icon-color);
    border-radius: var(--menu-icon-border-radius);
    display: block;
    height: var(--menu-icon-spacing);
    left: 0;
    position: absolute;
    transition: top var(--menu-icon-transition-timing) var(--menu-icon-transition-easing) var(--menu-icon-transition-timing),
      bottom var(--menu-icon-transition-timing) var(--menu-icon-transition-easing) var(--menu-icon-transition-timing),
      transform var(--menu-icon-transition-timing) var(--menu-icon-transition-easing);
    width: 100%;

    .is-active & {
      transition: top var(--menu-icon-transition-timing) var(--menu-icon-transition-easing),
        bottom var(--menu-icon-transition-timing) var(--menu-icon-transition-easing),
        transform var(--menu-icon-transition-timing) var(--menu-icon-transition-easing) var(--menu-icon-transition-timing);
    }

    &:nth-child(1) {
      top: calc(var(--menu-icon-spacing) / 2);
      transform: translateY(-50%) rotate(0deg);

      .is-active & {
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%) rotate(0deg);

      .is-active & {
        transform: translateY(-50%) rotate(45deg);
      }
    }

    &:nth-child(3) {
      bottom: calc(var(--menu-icon-spacing) / 2);
      transform: translateY(50%) rotate(0deg);

      .is-active & {
        bottom: 50%;
        transform: translateY(50%) rotate(-45deg);
      }
    }
  }
}
