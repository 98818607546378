/* ==========================================================================
 * Style Guide
 * ========================================================================== */

.sg-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  &__header {
    flex-shrink: 0;
    z-index: 300;
  }

  &__main {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    position: relative;
  }
}

.sg-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__title {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .c-logo {
    margin-right: var(--spacing-sm);
  }

  .c-navigation {
    justify-self: flex-end;

    @media screen and (max-width: 599px) {
      display: none;
    }
  }
}

.sg-sidebar {
  padding-bottom: var(--spacing-lg);
  padding-top: var(--spacing-sm);

  @media screen and (min-width: 900px) {
    flex-basis: 250px;
    flex-shrink: 0;
    position: relative;
  }

  &__menu {
    a {
      @apply --heading-sm;
      color: #fff;
      display: block;
      margin: 0;
      padding: var(--spacing-xs) var(--spacing-sm);

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    ul a {
      opacity: 0.5;
      padding-left: var(--spacing-med);
    }
  }
}

.sg-main {
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
}

.sg-section {
  padding-bottom: var(--spacing-lg);
  padding-top: calc(var(--spacing-lg) + 10px);

  &--depth-1 {
    background-color: var(--theme-color-accent);
    color: #fff;

    a {
      color: #fff;
    }
  }

  &--depth-2 {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &--depth-3 {
    padding-top: 0;
  }

  &__container {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--screen-width-max);
    padding-left: var(--spacing-med);
    padding-right: var(--spacing-med);
    width: 100%;
  }

  &__header {
    &:not(:last-child) {
      margin-bottom: var(--spacing-med);
    }
  }

  &__h1 {
    @apply --heading-xl;
  }

  &__h2 {
    @apply --heading-lg;
  }

  &__h3 {
    @apply --heading-med;
  }

  &__description {
    border-left: var(--border-default);
    max-width: 600px;
    padding-bottom: var(--spacing-sm);
    padding-left: var(--spacing-med);
    padding-top: var(--spacing-sm);

    &:not(:last-child) {
      margin-bottom: var(--spacing-med);
    }
  }

  &__markup-label {
    align-items: center;
    background: var(--color-beige);
    border: var(--border-default);
    border-bottom: 0;
    color: var(--theme-color-text-muted);
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-sm) var(--spacing-med);

    &:first-child {
      border-top: none;
      margin-top: 0;
      padding-top: 0;
    }

    code {
      margin: calc(var(--spacing-xs) * -1);
    }
  }

  &__markup {
    border: var(--border-default);
    border-bottom: 0;
    padding: var(--spacing-med);

    .sg-section.is-dark & {
      background: var(--background-color-dark);
    }
  }

  &__code {
    border: var(--border-default);
    border-radius: 0;
  }
}
