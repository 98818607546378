/* ==========================================================================
 * Map
 * ========================================================================== */

/*
  Map

  Markup:
  <div class="c-map js-map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3228.1284239375286!2d-78.90615818441583!3d35.992728580122915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89ace46ceeacf78f%3A0x29fc30f149e2fc48!2sSmashing+Boxes!5e0!3m2!1sen!2sus!4v1473361898571" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
  </div>

  Styleguide: Components.Map
*/

.c-map {
  height: 40vh;
  overflow: hidden;
  position: relative;

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &.is-inactive {
      pointer-events: none;
    }
  }
}
