/* ==========================================================================
 * Awards
 * ========================================================================== */

/*
  Awards

  Markup:
  <div class="c-awards">
    <div class="c-awards__item">
      <h4 class="c-awards__title h-heading--sm">Title of item with <br/> a date -- 2065</h4>
      <p class="c-awards__stat h-heading--xl h-text-transform-capitalize">Stat #1</p>
    </div>
    <div class="c-awards__item">
      <h4 class="c-awards__title h-heading--sm">Title of item with <br/> a date -- 2065</h4>
      <p class="c-awards__stat h-heading--xl h-text-transform-capitalize">Stat #1</p>
    </div>
    <div class="c-awards__item">
      <h4 class="c-awards__title h-heading--sm">Title of item with <br/> a date -- 2065</h4>
      <p class="c-awards__stat h-heading--xl h-text-transform-capitalize">Stat #1</p>
    </div>
    <div class="c-awards__item">
      <h4 class="c-awards__title h-heading--sm">Title of item with <br/> a date -- 2065</h4>
      <p class="c-awards__stat h-heading--xl h-text-transform-capitalize">Stat #1</p>
    </div>
  </div>

  Styleguide: Components.Awards
*/

.c-awards {
  display: flex;
  align-items: top;
  flex-wrap: wrap;

  &__item {
    text-align: center;
    box-sizing: border-box;
    padding-left: var(--spacing-med);
    padding-right: var(--spacing-med);
    min-width: 230PX;
    background-image: inline("./assets/svg/award-stars.svg");
    /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
    background-image: inline("./assets/img/award-stars.png");
    background-size: 150PX auto;
    padding-bottom: 60PX;
    background-position: center bottom;
    margin-top: var(--spacing-lg);
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 720px) {
      width: 25%;
    }
  }

  &__title {
    color: var(--theme-color-text-muted);
  }
}
