/* ==========================================================================
 * Footer
 * ========================================================================== */

/*
  Footer

  Markup:
  <footer class="l-footer">
    <div class="l-footer__container">
      <a class="c-logo c-logo--on-dark"></a>
      <nav class="l-footer__navigation">
        <a>Link</a>
        <a>Link</a>
        <a>Link</a>
        <a>Link</a>
        <a>Link</a>
        <a>Link</a>
        <a>Link</a>
        <a>Link</a>
        <a>Link</a>
        <a>Link</a>
      </nav>
    </div>
  </footer>

  Styleguide: Layout.Footer
*/

.l-footer {
  background-color: var(--background-color-dark);
  padding-bottom: var(--spacing-lg);
  padding-top: var(--spacing-lg);
  color: #fff;
  width: 100%;

  &__container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--screen-width-max);
    width: 100%;
    padding-left: var(--spacing-xl);
    padding-right: var(--spacing-xl);

    @media screen and (min-width: 720px) {
      padding-left: var(--spacing-med);
      padding-right: var(--spacing-med);
    }
  }

  &--subfooter {
    background-color: var(--background-color-darkest);
  }

  a {
    color: #fff;
  }

  .c-logo {
    margin-bottom: var(--spacing-lg);
  }

  .c-navigation {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: var(--spacing-med);
    padding-bottom: var(--spacing-med);

    @media screen and (min-width: 720px) {
      display: block;
      column-count: 2;
      column-width: 50%;
    }

    @media screen and (min-width: 1000px) {
      column-count: 3;
      column-width: 33%;
    }

    .menu-item {
      margin-right: 0;
      width: 100%;
      margin-bottom: var(--spacing-med);

      @media screen and (min-width: 720px) {
        margin-bottom: 0;
      }
    }

    .menu-item a {
      font-size: var(--font-size-med);

      @media screen and (min-width: 720px) {
        font-size: var(--font-size-sm);
      }
    }

    @media screen and (min-width: 720px) {
      width: 70%;
    }
  }
}
