/* ==========================================================================
 * Hamburger Menu
 * ========================================================================== */

/*
  Hamburger Menu

  Markup:
  <button class="c-hamburger js-toggle-active">
    <div class="c-hamburger__icon">
      <span class="c-hamburger__line"></span>
      <span class="c-hamburger__line"></span>
      <span class="c-hamburger__line"></span>
    </div>
  </button>

  Styleguide: Components.Hamburger Menu
*/

:root {
  --hamburger-width: 18PX; /* Don't convert to rem */
  --hamburger-height: 18PX; /* Don't convert to rem */
  --hamburger-border-radius: 0;
  --hamburger-color: var(--color-default);
  --hamburger-line-height: 4PX; /* Don't convert to rem */
  --hamburger-transition-easing: ease-in-out;
  --hamburger-transition-timing: 0.2s;
}

.c-hamburger {
  color: var(--hamburger-color);
  cursor: pointer;
  display: block;
  margin: calc(var(--spacing-xs) * -1);
  padding: var(--spacing-xs);

  &--on-dark {
    color: #fff;
  }

  &__icon {
    height: var(--hamburger-height);
    pointer-events: none;
    position: relative;
    width: var(--hamburger-width);
  }

  &__line {
    background-color: currentColor;
    border-radius: var(--hamburger-border-radius);
    display: block;
    height: var(--hamburger-line-height);
    left: 0;
    position: absolute;
    transition: top var(--hamburger-transition-timing) var(--hamburger-transition-easing),
      bottom var(--hamburger-transition-timing) var(--hamburger-transition-easing),
      color var(--hamburger-transition-timing) var(--hamburger-transition-easing),
      transform var(--hamburger-transition-timing) var(--hamburger-transition-easing);
    width: 100%;

    .is-active & {
      transition: top var(--hamburger-transition-timing) var(--hamburger-transition-easing),
        bottom var(--hamburger-transition-timing) var(--hamburger-transition-easing),
        transform var(--hamburger-transition-timing) var(--hamburger-transition-easing);
    }

    &:nth-child(1) {
      top: calc(var(--hamburger-line-height) / 2);
      transform: translateY(-50%) rotate(0deg);

      .is-active & {
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%) rotate(0deg);

      .is-active & {
        transform: translateY(-50%) rotate(45deg);
      }
    }

    &:nth-child(3) {
      bottom: calc(var(--hamburger-line-height) / 2);
      transform: translateY(50%) rotate(0deg);

      .is-active & {
        bottom: 50%;
        transform: translateY(50%) rotate(-45deg);
      }
    }
  }
}
