/* ==========================================================================
 * Slideout Menu
 * ========================================================================== */

.sg-overlay {
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  content: "";
  cursor: pointer;
  display: block;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 99;

  &.is-visible {
    opacity: 1;
    pointer-events: all;
  }
}

.sg-slideout-menu {
  background: var(--background-color-dark);
  bottom: 0;
  color: #fff;
  flex-shrink: 0;
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  transform: translateX(-100%);
  transition: box-shadow 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  width: 13rem;
  z-index: 100;

  &.is-visible {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    transform: translateX(0);
  }
}
