/* ==========================================================================
 * Photo Blocks
 * ========================================================================== */

/*
  Photo Blocks

  Markup:
  <div class="c-photo-blocks">
    <a class="c-photo-block">
      <div class="c-photo-block__content">
        <h6 class="h-heading h-heading--sm">Learn More About</h6>
        <h4 class="h-heading h-heading--xl">New York</h4>
      </div>
      <div class="c-photo-block__image" style="background-image: url(https://images.pexels.com/photos/28589/pexels-photo.jpg?w=1260&h=750&auto=compress&cs=tinysrgb)"></div>
    </a>
    <a class="c-photo-block">
      <div class="c-photo-block__content">
        <h6 class="h-heading h-heading--sm">Learn More About</h6>
        <h4 class="h-heading h-heading--xl">New York</h4>
      </div>
      <div class="c-photo-block__image" style="background-image: url(https://images.pexels.com/photos/28589/pexels-photo.jpg?w=1260&h=750&auto=compress&cs=tinysrgb)"></div>
    </a>
  </div>

  Styleguide: Components.Photo Blocks
*/

.c-photo-blocks {
  width: 100%;
  box-sizing: border-box;
  padding-right: var(--spacing-med);
  padding-left: var(--spacing-med);

  @media screen and (min-width: 800px) {
    align-items: stretch;
    display: flex;
    justify-content: center;
    padding-right: var(--spacing-xl);
    padding-left: var(--spacing-xl);
  }
}

.c-photo-block {
  align-items: center;
  border-radius: var(--border-radius-default);
  display: block;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  width: 100%;
  position: relative;
  transition: all 0.3s ease-in-out;

  @media screen and (min-width: 800px) {
    flex: 1;
  }

  &:hover {
    .c-photo-block__image {
      transform: scale(1.1);
    }
  }

  &::before {
    border: 2px solid #fff;
    border-radius: var(--border-radius-default);
    bottom: 0;
    content: "";
    left: 0;
    margin: var(--spacing-med);
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    min-width: 100%;
    padding-bottom: 100%;
  }

  &:not(:last-child) {
    margin-bottom: var(--spacing-med);

    @media screen and (min-width: 800px) {
      margin-right: var(--spacing-lg);
      margin-bottom: inherit;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-out;
    background-size: cover;
    background-position: center;

    &::after {
      background-color: rgba(0, 0, 0, 0.1);
      content: "";
      height: 100%;
      pointer-events: none;
      position: absolute;
      width: 100%;
    }

    @media screen and (min-width: 800px) {
      margin-right: var(--spacing-med);
    }
  }

  &__content {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    padding: calc(var(--spacing-med) * 2);
    text-align: center;
    width: 100%;

    & .h-heading--xl {
      display: block;
      max-width: 100%;
    }
  }
}
