/* ==========================================================================
 * Sections
 * ========================================================================== */

/*
  Sections

  Markup:
  <section class="l-section {{ modifier_class }}">
    <div class="l-section__container">
      <h2 class="h-heading h-heading--lg">Lorem ipsum dolor sit amet</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
    </div>
  </section>

  .l-section--beige - Beige Section
  .l-section--brown - Brown Section
  .l-section--orange - Orange Section

  Styleguide: Layout.Sections
*/
:root {
  --width-wide: 1100px;
  --width-narrow: 800px;
  --width-very-narrow: 600px;
}

@define-mixin l-section $background, $color: var(--theme-color-text) {
  background-color: $background;
  color: $color !important;

  &::after {
    background-color: $background !important;
    opacity: 0.85 !important;
  }

  & + & {
    padding-top: 0;
  }
}

.l-section {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: var(--spacing-xl);
  padding-top: var(--spacing-xl);
  position: relative;
  text-align: left;
  flex-direction: column;
  flex-wrap: wrap;

  @media screen and (min-width: 720px) {
    padding-top: var(--spacing-xxl);
    padding-bottom: var(--spacing-xxl);
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &--beige {
    @mixin l-section var(--color-beige);
    border-top: 1px solid var(--color-gray);
    border-bottom: 1px solid var(--color-gray);
  }

  &--brown {
    @mixin l-section var(--color-brown), #fff;
  }

  &--orange {
    @mixin l-section var(--color-orange), #fff;
  }

  &--no-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  &--no-padding-bottom {
    padding-bottom: 0;
  }

  &--short {
    padding-top: var(--spacing-lg);
    padding-bottom: var(--spacing-med);
  }

  &--divider {
    padding-bottom: 0;
  }

  &--faq {
    padding-top: var(--spacing-med);
  }

  &--divider > &__container {
    padding-top: var(--spacing-lg);
    padding-bottom: calc(var(--spacing-xl) / 1.5);
  }

  &--divider&:not(:last-child) > &__container {
    border-bottom: var(--border-default);
  }

  &--short-divider &__container::after {
    content: "";
    display: block;
    margin: var(--spacing-xl) auto 0 auto;
    width: calc(var(--spacing-xl) * 4);
    border-bottom: var(--border-default);
  }

  &__background {
    position: absolute;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &--beige &__background,
  &--brown &__background,
  &--orange &__background {
    background-color: inherit;
    background-blend-mode: multiply;
    opacity: 0.2;
  }

  &__container {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--screen-width-max);
    padding-left: var(--spacing-med);
    padding-right: var(--spacing-med);
    position: relative;
    width: 100%;
    z-index: 3;

    &--white {
      background-color: #fff;
      margin-top: var(--spacing-lg);
      padding-top: var(--spacing-lg);
      padding-bottom: var(--spacing-lg);
      margin-bottom: var(--spacing-lg);
    }

    @media screen and (min-width: 720px) {
      padding-left: var(--spacing-lg);
      padding-right: var(--spacing-lg);
      max-width: var(--width-wide);
    }

    /*
      Sections with Narrow Container

      Markup:
      <section class="l-section">
        <div class="l-section__container l-section__container--narrow">
          <h2 class="h-heading h-heading--lg">Lorem ipsum dolor sit amet</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </div>
      </section>

      Styleguide: Layout.Sections.Sections with Narrow Container
    */

    img {
      max-width: 100%;
      height: auto;
    }

    .c-button {
      margin-bottom: var(--spacing-med);
      margin-left: var(--spacing-xs);
      margin-right: var(--spacing-xs);
    }

    &--full-width {
      max-width: 100%;

      .c-content {
        overflow-x: scroll;

        table {
          width: auto;
        }
      }
    }

    &--wide {
      max-width: var(--width-wide);
    }

    &--narrow {
      max-width: var(--width-narrow);
    }

    &--very-narrow {
      max-width: var(--width-very-narrow);
    }
  }

  /*
    Sections with Backgrounds

    To achieve this effect, simply add a background image to `.l-section` via an inline style.

    Markup:
    <section class="l-section {{ modifier_class }} has-background" style="background-image: url('http://previews.123rf.com/images/dolgachov/dolgachov1307/dolgachov130700003/20557312-picture-of-young-team-or-group-of-doctors-Stock-Photo-doctors-doctor-medical.jpg');">
      <div class="l-section__container">
        <h2 class="h-heading h-heading--lg">Lorem ipsum dolor sit amet</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      </div>
    </section>

    .l-section--beige - Beige Section
    .l-section--brown - Brown Section
    .l-section--orange - Orange Section

    Styleguide: Layout.Sections.Sections with Backgrounds
  */

  &.has-background {
    color: #fff;
    overflow: hidden;

    &::before {
      background-blend-mode: luminosity, normal;
      background-color: inherit;
      background-image: inherit;
      background-position: center;
      background-size: cover;
      bottom: 0;
      content: "";
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }

    &::after {
      background-color: #000;
      bottom: 0;
      content: "";
      left: 0;
      opacity: 0.25;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
    }
  }

  &&--translucent-orange {
    .h-heading {
      font-family: var(--font-family-heading);
      font-style: normal;
    }

    &::after {
      background-color: color(var(--color-orange-med) a(80%));
      opacity: 1;
    }
  }

  /*
    Section Sizes

    The large section differs from the default section in that it applies a min-height of 50vh.

    Markup:
    <section class="l-section l-section--lg has-background" style="background-image: url('http://previews.123rf.com/images/dolgachov/dolgachov1307/dolgachov130700003/20557312-picture-of-young-team-or-group-of-doctors-Stock-Photo-doctors-doctor-medical.jpg');">
      <div class="l-section__container">
        <h1 class="h-heading h-heading--xl">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</h1>
      </div>
    </section>

    Styleguide: Layout.Sections.Section Sizes
  */

  &--lg {
    min-height: 50vh;
  }

  /*
    Split Section

    Markup:
    <section class="l-section l-section--split">
      <div class="l-section__container l-section__container--narrow">
        <div class="c-well">
          <h3 class="h-heading h-heading--sm h-text-color-muted">Ready to Enroll</h3>
          <h4 class="h-heading h-heading--med">Contact us to learn more about the benefits of becoming a part of the Orange Care Group</h4>
          <a class="c-button">Contact Us</a>
        </div>
      </div>
    </section>

    Styleguide: Layout.Sections.Split Section
  */

  &--split {
    background-image: linear-gradient(transparent 50%, var(--color-beige) 50%);
  }

  &--overlap-next-block {
    padding-bottom: 0;
    margin-bottom: -calc(var(--spacing-xl) * 2);
  }

  &--overlap-next-block + & {
    padding-top: calc(var(--spacing-xl) * 3);
  }

  &__container {
    &--flex-contents {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 720px) {
        flex-direction: row;
      }
    }

    & hr,
    .c-hr {
      display: block;
      width: 100px;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid var(--color-gray);
      margin-top: var(--spacing-med);
      margin-bottom: var(--spacing-med);
      margin-left: auto;
      margin-right: auto;
    }

    &--left-split {
      flex-wrap: wrap;
      display: flex;
      flex-direction: column;
    }

    @media screen and (min-width: 720px) {
      &--left-split {
        width: 30%;
        margin-left: var(--spacing-med);
        margin-right: var(--spacing-med);
      }

      &--right-split {
        width: 70%;
        margin-left: var(--spacing-med);
        margin-right: var(--spacing-med);
      }
    }
  }

  &--detail-split {
    max-width: 900px;
    align-items: flex-start;
  }

  &__svg-image {
    color: var(--color-orange-dark);
    margin: 0 0 var(--spacing-med) 0;
    flex-basis: 25%;

    > svg {
      height: 200PX;
      width: 100%;
    }

    @media screen and (min-width: 720px) {
      width: 200PX;
      min-width: 200PX;

      > svg {
        width: 80%;
      }

      > img {
        height: auto;
        width: 80%;
      }
    }
  }

  &__excerpt {
    flex-basis: 75%;
  }
}
