/* ==========================================================================
 * Mobile Menu
 * ========================================================================== */

/*
  Mobile Menu

  Styleguide: Components.Mobile Menu
*/

.c-mobile-menu {
  background: var(--color-orange);
  bottom: 0;
  left: 0;
  opacity: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: var(--spacing-sm) var(--spacing-med);
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 110;

  &.is-visible {
    opacity: 1;
    pointer-events: all;

    .c-mobile-menu__body {
      transform: scale(1);
    }
  }

  &__header {
    margin-bottom: var(--spacing-med);
  }

  .l-header__container {
    padding-left: 0;
    padding-right: 0;
  }

  &__body {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    margin-bottom: var(--spacing-lg);
    padding-left: 0;
    padding-right: 0;
    padding-top: 2rem;
    position: relative;
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
    width: 100%;
  }

  .c-mobile-searchbar {
    width: 100%;
    display: block;
    margin-bottom: var(--spacing-med);
  }

  .c-mobile-navigation {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    li.menu-item {
      flex: 1;
      text-align: left;
      width: 100%;
      margin-right: 0;

      &.menu-search {
        display: none;
      }

      > a {
        color: #fff;
        display: block;
        font-size: var(--font-size-med);
        line-height: var(--line-height-lg);
        position: relative;
        overflow: hidden;
        margin-bottom: 1.5em;

        &::before {
          content: "";
          display: block;
          height: 1em;
          width: 1em;
          opacity: 1;
          background-color: var(--theme-color-accent);
          transition: all 700ms ease-out;
          transform: translate(-20vw, 0.2em) scale(1);
          border-radius: 100%;
          position: absolute;
        }

        &:focus::before,
        &:hover::before,
        &:active::before {
          opacity: 0;
          transform: translate(10vw, 0.2em) scale(10);
        }
      }
    }
  }
}

