/* ==========================================================================
 * Links
 * ========================================================================== */

/*
  Links

  Markup:
  <a class="c-link" href="#">Link</a>

  Styleguide: Components.Links
*/

.c-link, .menu-item a {
  @apply --heading-sm;
  color: var(--theme-color-accent);
  display: inline-block;
  transition: all 300ms ease-out;
}

.c-link:hover,
.c-link:active,
.c-link:focus {
  color: var(--theme-color-accent-hover);
}

.c-info-links {
  display: flex;
  flex-direction: column;
  text-align: center;

  .c-link {
    margin-top: var(--spacing-sm);
    margin-bottom: var(--spacing-sm);
  }

  &::before,
  &::after {
    content: "";
    display: block;
    width: 100PX;
    border-bottom: 1px solid var(--color-gray);
    margin: var(--spacing-lg) auto;
  }
}
