/* ==========================================================================
 * Photo Circle
 * ========================================================================== */

/*
  Photo Circle

  Markup:
  <section class="c-photo-circles">
    <a href class="c-photo-circle c-photo-circle--link">
      <div class="c-photo-circle__image" style="background-image: url(https://images.pexels.com/photos/28589/pexels-photo.jpg?w=1260&h=750&auto=compress&cs=tinysrgb)"></div>
    </a>
    <div class="c-photo-circle">
      <div class="c-photo-circle__image" style="background-image: url(https://images.pexels.com/photos/28589/pexels-photo.jpg?w=1260&h=750&auto=compress&cs=tinysrgb)"></div>
    </div>
    <div class="c-photo-circle">
      <div class="c-photo-circle__image" style="background-image: url(https://images.pexels.com/photos/28589/pexels-photo.jpg?w=1260&h=750&auto=compress&cs=tinysrgb)"></div>
    </div>
  </section>

  Styleguide: Components.Photo Circle
*/
:root {
  --photo-circle-size: 240PX;
}

.c-photo-circles {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &__item {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--spacing-lg);

    > div,
    > p {
      margin-bottom: var(--spacing-sm);
      line-height: 1;
      width: 100%;
    }

    > a {
      margin-bottom: var(--spacing-med);
    }

    @media screen and (min-width: 720px) {
      width: 50%;
    }

    @media screen and (min-width: 1000px) {
      width: 33%;
    }

    @media screen and (min-width: 1200px) {
      width: 25%;
    }
  }
}

.c-photo-circle {
  align-items: center;
  align-self: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: var(--photo-circle-size);
  padding-top: var(--photo-circle-size);
  max-width: var(--photo-circle-size);
  max-height: var(--photo-circle-size);
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
  z-index: 1;

  @media screen and (min-width: 720px) {
    flex: 1;
  }

  &:hover {
    .c-photo-block__image {
      transform: scale(1.1);
    }
  }

  &::before {
    border: 2px solid #fff;
    border-radius: 50%;
    bottom: 0;
    content: "";
    left: 0;
    margin: var(--spacing-sm);
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    box-shadow: 0 0 1px 10px rgba(255, 124, 0, 0.5);
  }

  &::after {
    content: "";
    padding-bottom: 100%;
  }

  &--link &__image {
    isolation: isolate;

    &::after {
      background-color: var(--color-orange);
      content: "";
      display: block;
      height: 100%;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 50%;
      transform: scale(0.01);
      mix-blend-mode: multiply;
      opacity: 0;
      transition: opacity 250ms ease-out,
        transform 250ms cubic-bezier(0.4, 0, 0.61, 1.27);
    }

    &:hover::after,
    &:active::after,
    &:focus::after {
      opacity: 0.4;
      transform: scale(1);
    }
  }

  &--prefab {
    border-radius: 0;
  }

  &--prefab &::before {
    display: none;
  }

  &__image {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-out;
    background-size: cover;
    background-position: center;

    @media screen and (min-width: 720px) {
      &:not(:last-child) {
        margin-right: var(--spacing-med);
      }
    }
  }
}
