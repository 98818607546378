/* ==========================================================================
 * Well
 * ========================================================================== */

/*
  Well

  Markup:
  <div class="c-well">
    <h3 class="h-heading h-heading--sm h-text-color-muted">Ready to Enroll</h3>
    <h4 class="h-heading h-heading--med">Contact us to learn more about the benefits of becoming a part of the Orange Care Group</h4>
    <a class="c-button">Contact Us</a>
  </div>

  Styleguide: Components.Well
*/

.c-well {
  background-color: #fff;
  border: var(--border-default);
  border-radius: var(--border-radius-default);
  margin-left: auto;
  margin-right: auto;
  padding: var(--spacing-lg);
  text-align: center;
}
