/* ==========================================================================
 * Typography
 * ========================================================================== */

/*
  Typography

  Styleguide Helpers.Typography
*/

/* ==========================================================================
 * Font Style
 * ========================================================================== */

/*
  Font Style

  Markup:
  <p class="h-font-style-italic">Lorem ipsum dolor sit amet.</p>

  Styleguide: Helpers.Typography.Font Style
*/

.h-font-style-italic { font-style: italic !important; }

/* ==========================================================================
 * Font Weight
 * ========================================================================== */

/*
  Font Weight

  Markup:
  <p class="h-font-weight-normal">Lorem ipsum dolor sit amet.</p>
  <p class="h-font-weight-bold">Lorem ipsum dolor sit amet.</p>

  Styleguide: Helpers.Typography.Font Weight
*/

.h-font-weight-normal { font-weight: var(--font-weight-normal) !important; }
.h-font-weight-bold { font-weight: var(--font-weight-bold) !important; }
.h-font-size-up { font-size: 110%; }

/* ==========================================================================
 * Text Alignment
 * ========================================================================== */

/*
  Text Alignment

  Markup:
  <p class="h-text-align-left">Lorem ipsum dolor sit amet.</p>
  <p class="h-text-align-center">Lorem ipsum dolor sit amet.</p>
  <p class="h-text-align-right">Lorem ipsum dolor sit amet.</p>

  Styleguide: Helpers.Typography.Text Alignment
*/

.h-text-align-left { text-align: left !important; }
.h-text-align-center { text-align: center !important; }
.h-text-align-right { text-align: right !important; }
.h-text-align-justify { text-align: justify !important; }

/* ==========================================================================
 * Text Colors
 * ========================================================================== */

/*
  Text Colors

  Markup:
  <p class="h-text-color-muted">Lorem ipsum dolor sit amet.</p>

  Styleguide: Helpers.Typography.Text Colors
*/

.h-text-color-muted { color: var(--theme-color-text-muted) !important; }

/* ==========================================================================
 * Text Transforms
 * ========================================================================== */

/*
  Text Transforms

  Markup:
  <p class="h-text-transform-uppercase">Lorem ipsum dolor sit amet.</p>

  Styleguide: Helpers.Typography.Text Transforms
*/

.h-text-transform-uppercase { text-transform: uppercase !important; }
.h-text-transform-capitalize { text-transform: capitalize !important; }

/* ==========================================================================
 * Text Misc Helpers
 * ========================================================================== */

/*
  Text Misc Helpers

  Markup:
  <p class="h-expand-br">
    This copy should not <br/> break on mobile. <br/>
    This copy should not <br/> break on mobile. <br/>
    This copy should not <br/> break on mobile. <br/>
    This copy should not <br/> break on mobile. <br/>
    This copy should not <br/> break on mobile. <br/>
  </p>
  <p>Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. <span class="h-nobr">This phrase should be unbroken.</span> Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. </p>

  Styleguide: Helpers.Typography.Text Misc Helpers
*/
.h-expand-br {
  br {
    display: none;

    @media screen and (min-width: 720px) {
      display: block;
    }
  }
}

.h-nobr {
  white-space: nowrap;
}
