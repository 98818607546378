/* ==========================================================================
 * Hero Group
 * ========================================================================== */

/*
  Hero Group

  Markup:
  <section class="l-section l-section--no-padding c-hero-group">
    <div class="c-hero-group__background-image" style="background-image: url(https://images.pexels.com/photos/28589/pexels-photo.jpg?w=1260&h=750&auto=compress&cs=tinysrgb); outline: none;"></div>
    <div class="c-hero-group__content h-text-visibility-enhancer">
      <h1 class="c-hero-group__content-item h-heading h-heading--xl h-heading--knockout">Quality care as it should be.</h1>
      <a class="c-button c-button--transparent-white c-hero-group__content-item" href="http://orangecaregroup.dev/providers/">
        View our Providers
      </a>
    </div>
  </section>

  Styleguide: Components.Hero Group
*/

.c-hero-group {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  background-color: var(--theme-color-accent);

  .h-heading br {
    display: none;
  }

  @media screen and (min-width: 720px) {
    & {
      height: 400PX;
      background-color: #fff;
    }

    .h-heading br {
      display: block;
    }
  }

  @media screen and (min-width: 1000px) {
    & {
      height: 600PX;
      padding-left: var(--spacing-xl);
      padding-right: var(--spacing-xl);
    }
  }

  @media screen and (min-width: 1100px) {
    & {
      padding-left: var(--spacing-xxl);
      padding-right: var(--spacing-xxl);
    }
  }

  &__parallax-wrapper {
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 719px) {
      height: 200PX;
      position: relative;
    }
  }

  &--parallax &__background-image {
    top: -30%;
    transform: translate3d(0, 0, 0);
  }

  @media screen and (min-width: 720px) {
    &&--split-image-orange {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__background-image {
    position: absolute;
    z-index: 1;
    min-width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: 50% center;
    background-size: cover;

    @media screen and (min-width: 720px) {
      background-position: 100% 0%;
      height: 110%;
      top: -5%;

      &--pin-right {
        left: auto;
        right: 0;
      }

      &--half-width {
        min-width: 50%;
        width: 50%;
        background-position: 50%;
      }
    }

    &--orange-overlay {
      background-size: cover;
    }

    &--orange-overlay::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--theme-color-accent);
      display: block;
      opacity: 0.8;
    }

    @media screen and (min-width: 720px) {
      &--orange-overlay {
        background-size: 300% auto;
        background-position: 50%;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    text-align: center;
    box-sizing: border-box;
    max-width: 1200PX;
    padding-top: 200PX;
    padding-left: var(--spacing-med);
    padding-right: var(--spacing-med);
    padding-bottom: var(--spacing-med);

    @media screen and (max-width: 719px) {
      padding-top: 0;
    }

    .h-heading {
      padding-top: var(--spacing-med);
    }

    @media screen and (min-width: 720px) {
      padding: var(--spacing-lg);

      .h-heading {
        padding-top: 0;
      }
    }

    @media screen and (min-width: 1400px) {
      padding-left: var(--spacing-xxl);
      padding-right: var(--spacing-xxl);
    }

    &--pin-right-half {
      @media screen and (min-width: 720px) {
        width: 50%;
        margin-right: -50%;
      }
    }
  }

  @media screen and (min-width: 720px) {
    &--centered-wide-image &__background-image {
      background-position: 50% 0;
      background-size: cover;
    }

    &--centered-wide-image &__content {
      height: 320PX;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: space-between;
      justify-content: space-between;
    }

    &--centered-wide-image .h-heading {
      color: var(--theme-color-accent);
    }

    &--centered-wide-image &__content {
      height: 100%;
    }

    &--centered-wide-image &__background-image {
      background-position: center;
    }

    &--centered-wide-image .c-button {
      margin-bottom: -7%;
    }
  }
}
