/* ==========================================================================
 * Buttons
 * ========================================================================== */

/*
  Buttons

  Markup:
  <a class="c-button" href="#" role="button">Link</a>
  <button class="c-button" type="submit">Button</button>
  <input class="c-button" type="button" value="Input">
  <input class="c-button" type="submit" value="Submit">
  <input class="c-button" type="reset" value="Reset">

  Styleguide: Components.Buttons
*/

@define-mixin button $color, $color-hover {
  border-color: $color;
  color: $color;
  background-color: $color-hover;

  &.current,
  &:focus,
  &:hover {
    background-color: $color;
    color: $color-hover;
  }
}

.c-button {
  @apply --heading-sm;
  @mixin button var(--theme-color-accent), #fff;
  border: 2px solid var(--theme-color-accent);
  border-radius: var(--border-radius-default);
  display: inline-block;
  line-height: var(--line-height-sm);
  padding: var(--spacing-med) var(--spacing-lg);
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  transition: background-color 250ms ease-out,
    color 250ms ease-out,
    border-color 250ms ease-out;
  user-select: none;
  vertical-align: middle;
  cursor: pointer;

  &--small {
    padding: var(--spacing-sm) var(--spacing-med);
  }

  &--transparent {
    background-color: transparent;
  }

  &--transparent-white {
    @mixin button #fff, transparent;

    &.current,
    &:focus,
    &:hover {
      background-color: #fff;
      border-color: var(--theme-color-accent);
      color: var(--theme-color-accent);
    }
  }

  &--disabled {
    cursor: default;

    &.current,
    &:focus,
    &:hover {
      background-color: transparent;
      border-color: var(--theme-color-accent);
      color: var(--theme-color-accent);
    }
  }

  /*
    Buttons on Dark Backgrounds

    Markup:
    <button class="c-button c-button--on-dark">Button</button>

    Dark: true

    Styleguide: Components.Buttons.Buttons on Dark Backgrounds
  */

  &--on-dark { @mixin button #fff, var(--theme-color-accent); }

  /*
    Buttons with Icons

    Markup:
    <button class="c-button">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
      </svg>
    </button>
    <button class="c-button">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
      </svg>
      <span>Button</span>
    </button>
    <button class="c-button">
      <span>Button</span>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
      </svg>
    </button>

    Styleguide: Components.Buttons.Buttons with Icons
  */

  > svg {
    display: inline-block;
    height: 1em;
    line-height: inherit;
    pointer-events: none;
    transform: scale(2);
    vertical-align: middle;
    width: auto;

    &:first-child:not(:only-child) {
      margin-right: var(--spacing-sm);
    }

    &:last-child:not(:only-child) {
      margin-left: var(--spacing-sm);
    }
  }
}
