/* ==========================================================================
 * Headings
 * ========================================================================== */

/*
  Headings

  Markup:
  <h1 class="h-heading h-heading--xxl">Heading XXL</h1>
  <h1 class="h-heading h-heading--xl">Heading XL</h1>
  <h1 class="h-heading h-heading--lg">Heading LG</h1>
  <h1 class="h-heading h-heading--med">Heading MED</h1>
  <h1 class="h-heading h-heading--sm">Heading SM</h1>

  Styleguide: Helpers.Headings
*/

.h-heading {
  @apply --heading;

  &--xxl { @apply --heading-xxl; }
  &--xl { @apply --heading-xl; }
  &--lg { @apply --heading-lg; }
  &--med { @apply --heading-med; }
  &--sm { @apply --heading-sm; }

  &--knockout {
    color: #fff;
  }
}
