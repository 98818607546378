/* ==========================================================================
 * Content
 * ========================================================================== */

/*
  Content

  Markup:
  <div class="c-content">
    <h1>Heading 1</h1>
    <p>Lorem ipsum dolor sit amet, <a href="#">consectetur</a> adipiscing elit. Integer ultrices posuere dolor a porttitor. Maecenas ac blandit felis. Phasellus nec mi a nulla efficitur scelerisque id sit amet sapien. Etiam sit amet sapien odio. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aliquam erat volutpat. Morbi fermentum, nunc quis pretium aliquam, purus eros elementum sem, ac lobortis nisl ex sed nisl. Praesent quam odio, molestie eu neque sit amet, feugiat auctor leo. Praesent vestibulum est et nisi egestas blandit.</p>
    <h2>Heading 2</h2>
    <p>Lorem ipsum dolor sit amet, <a href="#">consectetur</a> adipiscing elit. Integer ultrices posuere dolor a porttitor. Maecenas ac blandit felis. Phasellus nec mi a nulla efficitur scelerisque id sit amet sapien. Etiam sit amet sapien odio. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aliquam erat volutpat. Morbi fermentum, nunc quis pretium aliquam, purus eros elementum sem, ac lobortis nisl ex sed nisl. Praesent quam odio, molestie eu neque sit amet, feugiat auctor leo. Praesent vestibulum est et nisi egestas blandit.</p>
    <hr>
    <h3>Heading 3</h3>
    <p>Lorem ipsum dolor sit amet, <a href="#">consectetur</a> adipiscing elit. Integer ultrices posuere dolor a porttitor. Maecenas ac blandit felis. Phasellus nec mi a nulla efficitur scelerisque id sit amet sapien. Etiam sit amet sapien odio. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aliquam erat volutpat. Morbi fermentum, nunc quis pretium aliquam, purus eros elementum sem, ac lobortis nisl ex sed nisl. Praesent quam odio, molestie eu neque sit amet, feugiat auctor leo. Praesent vestibulum est et nisi egestas blandit.</p>
    <h4>Heading 4</h4>
    <p>Lorem ipsum dolor sit amet, <a href="#">consectetur</a> adipiscing elit. Integer ultrices posuere dolor a porttitor. Maecenas ac blandit felis. Phasellus nec mi a nulla efficitur scelerisque id sit amet sapien. Etiam sit amet sapien odio. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aliquam erat volutpat. Morbi fermentum, nunc quis pretium aliquam, purus eros elementum sem, ac lobortis nisl ex sed nisl. Praesent quam odio, molestie eu neque sit amet, feugiat auctor leo. Praesent vestibulum est et nisi egestas blandit.</p>
    <ol>
      <li>List Item 1</li>
      <li>List Item 2
        <ul>
          <li>List Item 1</li>
          <li>List Item 2</li>
          <li>List Item 3</li>
        </ul>
      </li>
      <li>List Item 3</li>
    </ol>
    <ul>
      <li>List Item 1</li>
      <li>List Item 2
        <ul>
          <li>List Item 1</li>
          <li>List Item 2</li>
          <li>List Item 3</li>
        </ul>
      </li>
      <li>List Item 3</li>
    </ul>
  </div>

  Styleguide: Components.Content
*/

.c-content {
  h1,
  h2,
  h3,
  h4 {
    @apply --heading;
  }

  h1 { @apply --heading-xl; }
  h2 { @apply --heading-lg; }
  h3 { @apply --heading-med; }

  h4 {
    @apply --heading-sm;
    color: var(--theme-color-text-muted);
  }

  ol,
  ul {
    padding-left: var(--spacing-med);

    &:not(:last-child) {
      margin-bottom: var(--spacing-med);
    }
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  &--reporting {
    hr {
      width: 100PX;
      text-align: center;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid var(--color-gray) !important;
      padding: 0 0 var(--spacing-med) 0;
      margin: 0 auto var(--spacing-lg) auto;

      &::after {
        display: none;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: -2%;
      margin-right: -2%;
      padding: 0;
    }

    li {
      margin-bottom: var(--spacing-med);
      display: block;
      line-height: 1.1;
      text-align: center;
    }

    @media screen and (min-width: 720px) {
      li {
        flex-basis: 46%;
        margin-left: 2%;
        margin-right: 2%;
      }
    }

    @media screen and (min-width: 1000px) {
      li {
        flex-basis: 29%;
        margin-left: 2%;
        margin-right: 2%;
      }
    }
  }
}
