/* ==========================================================================
 * Main
 * ========================================================================== */

/*
  Main

  Markup:
  <main class="l-main">
    content...
  </main>

  Styleguide: Layout.Main
*/

.l-main {
  min-height: 60vh;
  position: relative;
  z-index: 1;
  opacity: 1;
  transition: opacity 120ms;
  box-sizing: border-box;

  .has-mobile-menu & {
    opacity: 0;
    padding-top: calc(var(--logo-height) * 0.6 + var(--spacing-sm) * 2);
  }

  &--white-page-bg {
    max-width: 1100px;
    margin: var(--spacing-xl) auto;
    background-color: #fff;
    border: var(--border-default);
  }
}
