/* ==========================================================================
 * Navigation
 * ========================================================================== */

/*
  Navigation

  Markup:
  <ul class="c-navigation">
    <li class="menu-item menu-about-us"><a href="#/about-orange-care-aco/">About Us</a></li>
    <li class="menu-item menu-providers"><a href="#/providers/">Providers</a></li>
    <li class="menu-item menu-beneficiaries"><a href="#/beneficiaries/">Beneficiaries</a></li>
    <li class="active menu-item menu-item-has-children menu-locations"><a href="#/locations/">Locations</a>
    <ul class="sub-menu">
      <li class="active menu-item menu-new-york"><a href="#/locations/new-york/">New York</a></li>
      <li class="menu-item menu-south-florida"><a href="#/locations/south-florida/">South Florida</a></li>
    </ul>
    </li>
    <li class="menu-item menu-search"><a href="#search">Search</a></li>
    <li class="menu-item menu-contact"><a href="#/contact-us/">Contact</a></li>
  </ul>

  Styleguide: Components.Navigation
*/

:root {
  --sub-menu-width: 250PX;
  --arrow-sizer: 30PX;
  --sub-menu-dropshadow: 5PX;
}

.c-navigation {
  align-items: center;
  display: flex;
  justify-content: center;

  .c-link,
  .menu-item {
    text-transform: uppercase;
    margin-right: 0;
    position: relative;

    .sub-menu {
      position: absolute;
      top: 100%;
      left: 50%;
      flex-direction: column;
      width: var(--sub-menu-width);
      margin-left: -calc(var(--sub-menu-width) / 2);
      padding-top: var(--arrow-sizer);
      padding-bottom: var(--spacing-sm);
      padding-right: var(--spacing-med);
      padding-left: var(--spacing-med);
      text-align: center;
      display: none;

      .menu-item {
        margin-right: 0;
        position: relative;
        z-index: 10;
      }

      &::before {
        z-index: 9;
        content: "";
        display: block;
        width: var(--arrow-sizer);
        height: var(--arrow-sizer);
        background-color: #fff;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(calc(var(--arrow-sizer) / -2), 0) rotate(45deg);
        box-shadow: -var(--sub-menu-dropshadow) -var(--sub-menu-dropshadow) var(--sub-menu-dropshadow) -var(--sub-menu-dropshadow) rgba(0, 0, 0, 0.3);
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        margin-top: var(--arrow-sizer);
        width: 100%;
        height: 100%;
        border-radius: var(--spacing-med);
        top: -calc(var(--arrow-sizer) - (var(--arrow-sizer) / 3));
        left: 0;
        z-index: 8;
        box-shadow: 0 0 var(--sub-menu-dropshadow) rgba(0, 0, 0, 0.3);
        background-color: #fff;
      }
    }

    &:hover .sub-menu {
      display: flex;
      flex-direction: column;
    }

    &:not(:last-child) {
      margin-right: var(--spacing-med);
    }

    @media screen and (min-width: 1200px) {
      &:not(:last-child) {
        margin-right: var(--spacing-lg);
      }
    }
  }
}
