/* ==========================================================================
 * Loader
 * ========================================================================== */

/*
  Loader

  Markup:
  <span class="c-loader in">
    <span class="c-loader__line"></span>
    <span class="c-loader__line"></span>
    <span class="c-loader__line"></span>
  </span>

  Styleguide: Components.Loader
*/

@keyframes spinning_left {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

@keyframes spinning_right {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    transform: rotate(-360deg);
    opacity: 1;
  }
}

:root {
  --loader-timing: 2000ms;
  --loader-timing-step: 250ms;
  --loader-size: 100PX;
  --loader-border-width: 9PX;
  --loader-border-spacing: 10PX;

  --loader-border {
    border-radius: 50%;
    border-bottom: var(--loader-border-width) solid var(--color-gray);
    border-left: var(--loader-border-width) solid var(--color-gray);
    border-top: var(--loader-border-width) solid var(--color-gray);
    border-right: var(--loader-border-width) solid rgba(0, 0, 0, 0);
    box-sizing: content-box;
  }

  @define-mixin loader_left $index {
    &:nth-child($(index)) {
      animation: spinning_left calc(var(--loader-timing) + $(index)) infinite linear calc(($(index) - 1) * var(--loader-timing-step));
      top: calc(($(index) - 1) * var(--loader-border-spacing));
      left: calc(($(index) - 1) * var(--loader-border-spacing));
      height: calc(var(--loader-size) - (($(index) - 1) * (var(--loader-border-spacing) * 2)));
      width: calc(var(--loader-size) - (($(index) - 1) * (var(--loader-border-spacing) * 2)));
    }
  }

  @define-mixin loader_right $index {
    &:nth-child($(index)) {
      animation: spinning_right calc(var(--loader-timing) + $(index)) infinite linear calc(($(index) - 1) * var(--loader-timing-step));
      top: calc(($(index) - 1) * var(--loader-border-spacing));
      left: calc(($(index) - 1) * var(--loader-border-spacing));
      height: calc(var(--loader-size) - (($(index) - 1) * (var(--loader-border-spacing) * 2)));
      width: calc(var(--loader-size) - (($(index) - 1) * (var(--loader-border-spacing) * 2)));
    }
  }
}

.c-loader {
  display: block;
  width: 100PX;
  height: 100PX;
  position: relative;
  margin: 0 auto;
  transition: transform 300ms;
  transform: scale(1);

  &--pin-fixed {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50PX;
  }

  &--out {
    visibility: hidden;
    transform: scale(0.001);
  }

  &__line {
    @apply --loader-border;
    position: absolute;
    display: block;
    content: "";

    @mixin loader_left 1;
    @mixin loader_right 2;
    @mixin loader_left 3;
  }
}
