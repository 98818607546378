.c-social-links {
  display: flex;
  justify-content: flex-end;

  .c-mobile-menu & {
    width: 100%;
    justify-content: flex-start;
  }
}

.c-social__link {
  height: auto;

  .l-footer__container & {
    margin-right: 10PX;
    width: 25PX;

    @media screen and (min-width: 720px) {
      width: 15PX;
      margin-left: 10PX;
      margin-right: 0;
    }
  }

  .c-mobile-menu & {
    width: 25PX;
    margin-right: 20PX;
  }

  img {
    width: 100%;
  }
}

