/* ==========================================================================
 * Multi Column
 * ========================================================================== */

/*
  Multi Column

  Markup:
  <div class="c-multicolumn">
    <div class="c-multicolumn__item">
      <div class="c-multicolumn__graphic">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="50 50 200 200">
          <path d="M185.3,171.5v7.2h-7.2v17h7.2v7.2h17v-7.2h7.2v-17h-7.2v-7.2H185.3z M205.6,182.6v9.1h-7.2v7.2h-9.1v-7.2h-7.2v-9.1h7.2
          v-7.2h9.1v7.2H205.6z M223.6,157.6c0.3-2.8,0.5-5.7,0.5-8.6c0-30.6-18.6-56.8-45.1-68.1l0.5-2.8c0.2-1.1-0.1-2.1-0.8-3
          c-0.7-0.8-1.7-1.3-2.8-1.3h-29.1c-1.1,0-2.2,0.5-2.8,1.4c-38,3.1-68,34.9-68,73.7c0,1.4,0.1,2.7,0.1,4.1l-2.4,2.1
          c-0.9,0.8-1.2,2.3-0.4,3.3c0.5,0.6,1.1,0.9,1.8,0.9c0.5,0,0.9-0.1,1.3-0.4l0.1-0.1c0.7,5,1.9,9.9,3.5,14.6h-1.7
          c-0.8,0-1.4,0.6-1.4,1.4v49.3h29.1v-15.6c4.5,3.3,9.4,6.1,14.5,8.4v7.3H150v-1.2c0.1,0,0.1,0,0.2,0c4.9,0,9.7-0.5,14.4-1.5v2.6h28.6
          l0.5,0.2l0.9-0.4c20.1-10.1,32.8-30.7,32.8-53.1v-9.5C227.2,159.3,225.6,157.7,223.6,157.6z M175.6,77.9l-5.6,29.9l-8.5-11.3
          l-67.3,45.9l60.7-54.7l-7.3-9.7H175.6z M80.1,149.7c0-0.2,0-0.4,0-0.6c0-36.5,28.1-66.6,63.8-69.7c0.1,0.1,0.1,0.2,0.2,0.3l5.5,7.4
          L80.1,149.7z M102.4,220.3H81.2v-42.8h21.2V220.3z M146,220.3h-21.2v-62.9H146V220.3z M164.5,217.6c-4.7,1-9.5,1.5-14.5,1.5
          c0,0,0,0,0,0v-64.3c0-0.8-0.6-1.4-1.4-1.4h-26.3c-0.8,0-1.4,0.6-1.4,1.4v57.8c-5.2-2.4-10.1-5.4-14.5-9v-28.7c0-0.8-0.6-1.4-1.4-1.4
          H84.5c-2-5.4-3.4-11.1-4-17.1l80.1-54.7l6.6,8.8c0.7,0.9,1.7,1.6,2.9,1.6c1.8,0,3.3-1.2,3.6-2.9l4.5-24.3
          c24.6,10.9,41.9,35.5,41.9,64.1c0,2.8-0.2,5.6-0.5,8.3c-7.2-0.7-14.3-3.4-20.1-7.8l-3-2.3c-0.9-0.6-1.9-0.9-2.9-0.9V123
          c0-0.8-0.6-1.4-1.4-1.4h-26.3c-0.8,0-1.4,0.6-1.4,1.4v34.6c-0.2,0-0.3,0-0.5,0h-0.4c-2.1,0-3.7,1.7-3.7,3.7v9.5
          c0,8.1,1.6,15.9,4.6,23V217.6z M189.7,125.6v22.8l-1.9,1.4c-5.6,4.2-12.4,6.8-19.3,7.5v-31.8H189.7z M168.4,220.3v-18.6
          c4.4,7.3,10.5,13.7,17.8,18.6H168.4z M223.3,170.8c0,20.8-11.4,39.5-29.7,49.2c-18.4-9.6-29.7-28.4-29.7-49.2v-9.3h0.2
          c9.3,0,18.6-3.1,26.1-8.6l3.4-2.5c0.2-0.2,0.5-0.2,0.7,0l3,2.3c7.5,5.6,16.8,8.7,26.1,8.8V170.8z"></path>
        </svg>
      </div>
      <div class="c-multicolumn__text">
        <p>Improve patient care through better coordination among physician providers, assisting the patient every step of the way.</p>
      </div>
    </div>
    <div class="c-multicolumn__item">
      <div class="c-multicolumn__graphic">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="50 50 200 200">
          <path d="M149.6,75.7c-40.8,0-74,33.2-74,74c0,40.8,33.2,74,74,74c40.8,0,74-33.2,74-74C223.6,108.9,190.4,75.7,149.6,75.7z
          M149.6,219.7c-38.6,0-70-31.4-70-70s31.4-70,70-70s70,31.4,70,70S188.2,219.7,149.6,219.7z M155.8,119.4c12.1,2.5,21.6,11.9,24,24
          c0.4,2,2.1,3.4,4.1,3.4H209c1.2,0,2.3-0.5,3.1-1.4c0.8-0.9,1.2-2.1,1-3.2c-3.4-29.6-26.5-52.7-56.1-56.1c-1.2-0.1-2.3,0.2-3.2,1
          c-0.9,0.8-1.4,1.9-1.4,3.1v25.1C152.4,117.3,153.8,119,155.8,119.4z M156.4,90.2c0-0.1,0.1-0.2,0.2-0.2
          c27.8,3.2,49.4,24.9,52.6,52.8c0,0-0.1,0.1-0.2,0.1H184c-0.1,0-0.2-0.1-0.2-0.2c-2.8-13.7-13.4-24.3-27.1-27.1
          c-0.1,0-0.2-0.1-0.2-0.2V90.2z M212.2,153.9c-0.8-0.9-1.9-1.4-3.1-1.4H184c-2,0-3.7,1.4-4.1,3.4c-2.5,12.1-11.9,21.6-24,24
          c-2,0.4-3.4,2.1-3.4,4.1v25.1c0,1.2,0.5,2.3,1.4,3.1c0.8,0.7,1.7,1.1,2.8,1.1c0.2,0,0.3,0,0.5,0c29.6-3.4,52.7-26.5,56.1-56.1
          C213.3,155.9,213,154.8,212.2,153.9z M156.5,209.3c0,0-0.1-0.1-0.1-0.2V184c0-0.1,0.1-0.2,0.2-0.2c13.7-2.8,24.3-13.4,27.1-27.1
          c0-0.1,0.1-0.2,0.2-0.2H209c0.1,0,0.2,0.1,0.2,0.2C206.1,184.4,184.4,206.1,156.5,209.3z M90.2,146.8h25.1c2,0,3.7-1.4,4.1-3.4
          c2.5-12.1,11.9-21.6,24-24c2-0.4,3.4-2.1,3.4-4.1V90.2c0-1.2-0.5-2.3-1.4-3.1c-0.9-0.8-2.1-1.2-3.2-1c-29.6,3.4-52.7,26.5-56.1,56.1
          c-0.1,1.2,0.2,2.3,1,3.2C87.9,146.3,89,146.8,90.2,146.8z M142.8,90c0,0,0.1,0.1,0.1,0.2v25.1c0,0.1-0.1,0.2-0.2,0.2
          c-13.7,2.8-24.3,13.4-27.1,27.1c0,0.1-0.1,0.2-0.2,0.2H90.2c-0.1,0-0.2-0.1-0.2-0.2C93.2,114.9,114.8,93.2,142.8,90z M143.4,179.9
          c-12.1-2.5-21.6-11.9-24-24c-0.4-2-2.1-3.4-4.1-3.4H90.2c-1.2,0-2.3,0.5-3.1,1.4c-0.8,0.9-1.2,2.1-1,3.2
          c3.4,29.6,26.5,52.7,56.1,56.1c0.2,0,0.3,0,0.5,0c1,0,2-0.4,2.8-1.1c0.9-0.8,1.4-1.9,1.4-3.1V184C146.8,182,145.4,180.3,143.4,179.9
          z M142.8,209.1c0,0.1-0.1,0.2-0.2,0.2c-27.8-3.2-49.4-24.9-52.6-52.8c0,0,0.1-0.1,0.2-0.1h25.1c0.1,0,0.2,0.1,0.2,0.2
          c2.8,13.7,13.4,24.3,27.1,27.1c0.1,0,0.2,0.1,0.2,0.2V209.1z M169.9,138.7l-4.6-4.6c-1.3-1.3-3.4-1.3-4.7,0l-16.9,16.9l-7.3-7.3
          c-1.3-1.3-3.4-1.3-4.7,0l-4.6,4.6c-0.6,0.6-1,1.5-1,2.3c0,0.9,0.3,1.7,1,2.3l14.2,14.2c0.6,0.6,1.5,1,2.3,1c0.9,0,1.7-0.3,2.3-1
          l23.8-23.8C171.1,142.1,171.1,140,169.9,138.7z M143.7,164l-13.3-13.3l3.7-3.7l9.6,9.6l19.3-19.3l3.7,3.7L143.7,164z"></path>
        </svg>
      </div>
      <div class="c-multicolumn__text">
        <p>Ensure the most current evidence-based medicine is built into wellness and care plans.</p>
      </div>
    </div>
    <div class="c-multicolumn__item">
      <div class="c-multicolumn__graphic">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="50 50 200 200">
          <path d="M190.6,121.1c-0.7,0.2-1.2,0.8-1.4,1.5l-3.4,15.8l-7.8-28.3c-0.2-0.8-0.9-1.4-1.8-1.4c-0.9-0.1-1.6,0.4-2,1.2l-9.4,21.8
          h-9.5l-11.8-11.4c-0.5-0.5-1.3-0.7-2-0.5c-0.7,0.2-1.2,0.8-1.3,1.6l-2.4,16l-7.8-27.3c-0.2-0.8-1-1.4-1.9-1.4
          c-0.9-0.1-1.7,0.6-1.9,1.4l-7.1,23.5H100v4h20.6c0.9,0,1.6-0.6,1.9-1.4l5.6-18.5l8.4,29.5c0.2,0.9,1,1.4,1.9,1.4c0,0,0.1,0,0.1,0
          c0.9-0.1,1.7-0.8,1.8-1.7l3.2-21.2l9.6,9.3c0.4,0.4,0.9,0.6,1.4,0.6h11.6c0.8,0,1.5-0.5,1.8-1.2l7.7-17.8l8.5,30.6
          c0.2,0.9,1,1.4,1.9,1.4c0,0,0,0,0.1,0c0.9,0,1.7-0.7,1.9-1.6l4.4-20.2l7.1,6.7l2.7-2.9l-9.6-9.2C192,121.1,191.3,120.9,190.6,121.1z
          M212,108.9V95.4c0-4.6-3.8-8.4-8.4-8.4h-14c-11.4-7.2-24.8-11.3-39.2-11.3c-14.4,0-27.8,4.2-39.2,11.3h-12c-4.6,0-8.4,3.8-8.4,8.4
          V106c-9,12.3-14.4,27.4-14.4,43.8c0,40.8,33.2,74,74,74s74-33.2,74-74C224.4,134.6,219.8,120.6,212,108.9z M150.4,79.7
          c11.2,0,21.8,2.7,31.2,7.4h-62.4C128.6,82.4,139.2,79.7,150.4,79.7z M94.7,95.4c0-2.4,2-4.4,4.4-4.4h104.5c2.4,0,4.4,2,4.4,4.4v65.8
          H94.7V95.4z M94.7,165.2h113.3v10.5c0,2.4-2,4.4-4.4,4.4H99.2c-2.4,0-4.4-2-4.4-4.4V165.2z M139.5,193.7l-5.3-9.6h33.5l-4.9,9.6
          H139.5z M177.2,197.6v8.7l-50.2,0l0-8.7L177.2,197.6z M150.4,219.8c-38.6,0-70-31.4-70-70c0-13.5,3.8-26,10.4-36.7v62.7
          c0,4.6,3.8,8.4,8.4,8.4h30.5l5.3,9.6H127c-2.2,0-3.9,1.8-3.9,3.9v8.7c0,2.2,1.8,3.9,3.9,3.9h50.3c2.2,0,3.9-1.8,3.9-3.9v-8.7
          c0-2.2-1.8-3.9-3.9-3.9h-9.9l4.9-9.6h31.5c4.6,0,8.4-3.8,8.4-8.4v-59.2c5.3,9.9,8.4,21.2,8.4,33.2
          C220.4,188.4,189,219.8,150.4,219.8z"></path>
        </svg>
      </div>
      <div class="c-multicolumn__text">
        <p>Leverage proprietary technology to customize solutions based on practice and patient needs, contributing to lower health-care costs for the population.</p>
      </div>
    </div>
  </div>

  Styleguide: Components.MultiColumn
*/

.c-multicolumn {
  display: flex;
  align-items: top;
  flex-wrap: wrap;
  box-sizing: border-box;

  &__graphic {
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    width: 30%;

    @media screen and (min-width: 720px) {
      padding-left: var(--spacing-lg);
      padding-right: var(--spacing-lg);
      box-sizing: border-box;
      width: 100%;
    }

    @media screen and (min-width: 1000px) {
      padding-left: var(--spacing-xl);
      padding-right: var(--spacing-xl);
    }

    > img,
    > svg {
      display: inline-block;
      width: 100%;
    }
  }

  &__item {
    padding-left: var(--spacing-med);
    padding-right: var(--spacing-med);
    padding-bottom: var(--spacing-med);
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    width: 100%;
    text-align: center;

    @media screen and (min-width: 720px) {
      width: 33%;
    }
  }
}
