/* ==========================================================================
 * Tables
 * ========================================================================== */

/*
  Tables

  Markup:
  <table class="c-table">
    <tr>
      <th>Table Header 1</th>
      <th>Table Header 2</th>
      <th>Table Header 3</th>
    </tr>
    <tr>
      <td><a class="c-link" href="#">Division 1</a></td>
      <td>Division 2</td>
      <td>Division 3</td>
    </tr>
    <tr>
      <td><a class="c-link" href="#">Division 1</a></td>
      <td>Division 2</td>
      <td>Division 3</td>
    </tr>
    <tr>
      <td><a class="c-link" href="#">Division 1</a></td>
      <td>Division 2</td>
      <td>Division 3</td>
    </tr>
  </table>

  Styleguide: Components.Tables
*/

.c-content table,
.c-table {
  text-align: left;
  flex-basis: 100%;
  background-color: #fff;

  tr {
    &:nth-child(even) {
      background-color: var(--color-beige);
    }
  }

  th {
    @apply --heading-sm;
  }

  th,
  td {
    padding: var(--spacing-med) var(--spacing-med);
  }
}

.c-content table {
  font-size: var(--font-size-sm);
  line-height: 1;

  th,
  td {
    vertical-align: top;
    padding: var(--spacing-sm) var(--spacing-sm);
  }

  col {
    display: none;
  }
}
