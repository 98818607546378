/* ==========================================================================
 * Forms
 * ========================================================================== */

/*
  Forms

  Markup:
  <form class="c-form">
    <fieldset>
      <div class="c-form-group">
        <label class="c-form-label" for="text-field">Text Field</label>
        <input class="c-input" id="text-field" type="text" placeholder="Placeholder Text">
      </div>
      <div class="c-form-group">
        <label class="c-form-label" for="disabled-text-field">Disabled Text Field</label>
        <input class="c-input" id="disabled-text-field" type="text" placeholder="Placeholder Text" disabled>
      </div>
      <div class="c-form-group">
        <label class="c-form-label" for="text-area">Text Area</label>
        <textarea class="c-input" id="text-area"></textarea>
      </div>
      <div class="c-form-group">
        <label class="c-form-label" for="password">Password</label>
        <input class="c-input" id="password" type="password">
      </div>
      <label class="c-form-group" for="file">
        <div class="c-form-label">File Input</div>
        <input class="h-hidden" id="file" type="file">
        <div class="c-input c-input--file-upload">Drag a file here or <a href="#">browse</a> for a file to upload.</div>
      </label>
      <div class="c-form-group">
        <label class="c-form-label" for="select">Select</label>
        <span class="c-input__select">
          <select class="c-input" id="select">
            <optgroup label="Group 1">
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </optgroup>
            <optgroup label="Group 2">
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </optgroup>
          </select>
        </span>
      </div>
      <div class="c-form-group">
        <label class="c-form-label" for="select-multiple">Select Multiple</label>
        <select class="c-input" id="select-multiple" multiple>
          <option value="1">Option 1</option>
          <option value="2">Option 2</option>
          <option value="3">Option 3</option>
        </select>
      </div>
    </fieldset>
  </form>

  Styleguide: Components.Forms
*/

@keyframes invalid {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(10px, 0);
  }

  75% {
    transform: translate(-10px, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

.c-form {
  max-width: 500px;

  &:not(:last-child) {
    margin-bottom: var(--spacing-med);
  }

  &__fieldset {
    padding-left: var(--spacing-med);

    &:not(:last-child) {
      margin-bottom: var(--spacing-med);
    }
  }

  &__legend {
    @apply --heading-sm;
    color: var(--theme-color-text-muted);
    margin-bottom: var(--spacing-med);
    margin-left: calc(var(--spacing-med) * -1);
  }

  &--flexible {
    max-width: 100%;
  }
}

.c-form-group {
  display: block;

  &:not(:last-child) {
    margin-bottom: var(--spacing-med);
  }

  small {
    color: var(--color-gold-light);
    font-size: var(--font-size-xs);
  }
}

.c-form-label {
  @apply --heading-sm;
  color: var(--theme-color-text-muted);
  display: block;
  margin-bottom: var(--spacing-sm);
}

.c-input {
  background-color: var(--theme-color-input);
  border: 2px solid var(--color-gold-light);
  border-radius: var(--border-radius-default);
  display: block;
  font-size: var(--font-size-med);
  line-height: var(--line-height-sm);
  padding: var(--spacing-med);
  width: 100%;

  &--file-upload {
    border-style: dashed;
  }

  &__select {
    position: relative;
    width: 100%;
    height: calc(var(--spacing-med) * 3);
    display: block;

    &::after {
      border-radius: 0 var(--border-radius-default) var(--border-radius-default) 0;
      pointer-events: none;
      display: block;
      position: absolute;
      width: 70px;
      text-align: center;
      top: 0;
      right: 0;
      color: #fff;
      border: 2px solid rgba(0, 0, 0, 0);
      font-size: var(--font-size-med);
      line-height: var(--line-height-sm);
      padding: var(--spacing-med);
      content: ".";
      background-color: var(--color-gold-light);
      background-image: inline("./assets/img/down-arrow.png");
      /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
      background-image: inline("./assets/img/down-arrow.svg");
      background-position: center;
    }
  }

  select& {
    &:not([multiple]) {
      appearance: none;

      &::-ms-expand {
        display: none;
      }
    }
  }
}

.js-invalid {
  animation: invalid 300ms 5;
}

/*
  Success and Error Messages

  Let the user know upon form submission success or failure

  Markup:
  <p class="c-error">Error Message</p>
  <p class="c-success">Success Message</p>

  Styleguide: Components.Forms.Messages
*/

.c-error {
  color: #dc362a !important;
  font-weight: 700;
}

.c-success {
  color: #2f9434 !important;
  font-weight: 700;
}

/*
  Disabled Elements

  Disable form elements using the `disbled` attribute.

  Markup:
  <div class="c-form-group">
    <label class="c-form-label" for="text-field">Text Field</label>
    <input class="c-input" id="text-field" type="text" placeholder="Placeholder Text" disabled>
  </div>
  <button class="c-button" disabled>Button</button>

  Styleguide: Components.Forms.Disabled
*/

[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
