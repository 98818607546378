/* ==========================================================================
 * Physicians List
 * ========================================================================== */

/*
  Physicians List

  Markup:
  <div class="c-physicians-list">
    <div class="c-link">Dr. John Doe</div>
    <div class="c-link">Dr. John Doe</div>
    <div class="c-link">Dr. John Doe</div>
    <div class="c-link">Dr. John Doe</div>
    <div class="c-link">Dr. John Doe</div>
    <div class="c-link">Dr. John Doe</div>
    <div class="c-link">Dr. John Doe</div>
    <div class="c-link">Dr. John Doe</div>
    <div class="c-link">Dr. John Doe</div>
    <div class="c-link">Dr. John Doe</div>
    <div class="c-link">Dr. John Doe</div>
    <div class="c-link">Dr. John Doe</div>
  </div>

  Styleguide: Components.Physicians List
*/

.c-physicians-list {
  text-align: center;

  @media screen and (min-width: 720px) {
    display: flex;
    flex-wrap: wrap;
  }

  &:not(:last-child) {
    margin-bottom: var(--spacing-med);
  }

  .c-link {
    display: block;
    margin-bottom: var(--spacing-xs);

    @media screen and (min-width: 720px) {
      flex: 1;
      flex-basis: 50%;
    }

    @media screen and (min-width: 900px) {
      flex-basis: 33.33%;
    }
  }
}
