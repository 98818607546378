/* ==========================================================================
 * Grid
 * ========================================================================== */

/*
  Grid

  Markup:
  <div class="l-grid">
    <div class="l-grid__column">
      Column 1
    </div>
    <div class="l-grid__column">
      Column 2
    </div>
  </div>
  <div class="l-grid">
    <div class="l-grid__column">
      Column 1
    </div>
    <div class="l-grid__column">
      Column 2
    </div>
    <div class="l-grid__column">
      Column 3
    </div>
  </div>
  <div class="l-grid">
    <div class="l-grid__column">
      Column 1
    </div>
    <div class="l-grid__column">
      Column 2
    </div>
    <div class="l-grid__column">
      Column 3
    </div>
    <div class="l-grid__column">
      Column 4
    </div>
  </div>

  Styleguide: Layout.Grid
*/

.l-grid {
  display: flex;

  @media screen and (max-width: 720px) {
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: var(--spacing-med);
    }
  }

  &__column {
    flex: 1;

    &:not(:last-child) {
      margin-bottom: var(--spacing-med);

      @media screen and (min-width: 720px) {
        margin-right: var(--spacing-med);
      }
    }
  }
}
