/* ==========================================================================
 * Logo
 * ========================================================================== */

/*
  Logo

  Markup:
  <a class="c-logo {{ modifier_class }}"></a>

  .c-logo--sm - Small Logo

  Styleguide: Components.Logo
*/

.c-logo {
  background-image: inline("./assets/img/logo.svg");
  background-position: left center;
  display: block;
  height: calc(var(--logo-height) * 0.6);
  width: calc(var(--logo-width) * 0.6);

  &--sm {
    height: calc(var(--logo-height) * 0.3);
    width: calc(var(--logo-width) * 0.3);
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    body & {
      background-image: inline("./assets/img/logo.png");
      background-size: contain;
    }
  }

  /*
    Logo on Dark Backgrounds

    Markup:
    <a class="c-logo c-logo--on-dark {{ modifier_class }}"></a>

    .c-logo--sm - Small Logo

    Dark: true

    Styleguide: Components.Logo.Logo on Dark Backgrounds
  */

  &--on-dark {
    background-image: inline("./assets/img/logo--on-dark.svg");

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      body & {
        background-image: inline("./assets/img/logo--on-dark.png");
        background-size: contain;
      }
    }  
  }
}

