/* ==========================================================================
 * Variables
 * ========================================================================== */

:root {
  --background-color-default: #fff;
  --background-color-dark: var(--color-brown);
  --background-color-darkest: var(--color-brown-dark);
  --border-default: 1px solid var(--theme-color-border);
  --border-radius-default: 5px;
  --box-shadow-default: 0 1px 1px rgba(0, 0, 0, 0.25);
  --box-shadow-inset: var(--box-shadow-default) inset;
  --box-shadow-elevated: 0 1px 3px rgba(0, 0, 0, 0.1),
    0 6px 24px rgba(0, 0, 0, 0.1);
  --color-beige: #f7f1eb;
  --color-gray: #dcdcdc;
  --color-brown-dark: #47413b;
  --color-brown: #504b45;
  --color-brown-light: #63584c;
  --color-gold: #9e9388;
  --color-gold-light: #eae3dd;
  --color-off-white: #f9f9f9;
  --color-orange: #ff7c00;
  --color-orange-med: #f98b37;
  --color-orange-dark: #ce6329;
  --color-default: var(--color-brown);
  --font-family-default: "Work Sans", sans-serif;
  --font-family-heading: "Poppins", sans-serif;
  --font-family-accent: "Lora", serif;
  --font-size-default: var(--font-size-med);
  --font-size-xxl: 1.875rem;
  --font-size-xl: 1.375rem;
  --font-size-lg: 1.1rem;
  --font-size-med: 0.9rem;
  --font-size-sm: 0.75rem;
  --font-size-xs: 0.625rem;
  --font-weight-default: var(--font-weight-normal);
  --font-weight-bold: 700;
  --font-weight-normal: 400;
  --font-weight-light: 100;
  --letter-spacing-sm: 0.1em;
  --line-height-default: 1.8;
  --line-height-lg: 1.6;
  --line-height-sm: 1.3;
  --screen-width-min: 300px;
  --screen-width-max: 1200px;
  --spacing-xxl: 6rem;
  --spacing-xl: 4rem;
  --spacing-lg: 2rem;
  --spacing-med: 1rem;
  --spacing-sm: 0.5rem;
  --spacing-xs: 0.25rem;
  --text-shadow-default: 0 0 5px rgba(0, 0, 0, 0.5);
  --theme-color-accent: var(--color-orange);
  --theme-color-accent-hover: var(--color-orange-dark);
  --theme-color-border: var(--color-gold-light);
  --theme-color-input: var(--color-off-white);
  --theme-color-text: var(--color-brown-light);
  --theme-color-text-muted: var(--color-gold);
  --transition-duration-default: 0.2s;
  --transition-timing-default: ease-in-out;
  --transition-default: all var(--transition-duration-default) var(--transition-timing-default);
  --logo-width: calc(width("./assets/img/logo.svg") + 2px);
  --logo-height: height("./assets/img/logo.svg");

  /* Headings */
  --heading {
    &:not(:last-child) {
      margin-bottom: var(--spacing-med);
    }
  }

  --heading-xxl: {
    @mixin rt 25, 45;
    font-family: var(--font-family-heading);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-sm);
    text-transform: uppercase;
  }

  --heading-xl: {
    @mixin rt 25, 35;
    font-family: var(--font-family-heading);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-sm);
    text-transform: uppercase;
  }

  --heading-lg: {
    @mixin rt 22, 28;
    font-family: var(--font-family-accent);
    font-weight: var(--font-weight-bold);
  }

  --heading-med: {
    @mixin rt 20, 25;
    font-family: var(--font-family-accent);
    font-style: italic;
    font-weight: var(--font-weight-light);
  }

  --heading-sm: {
    @mixin rt 13, 13;
    font-family: var(--font-family-heading);
    font-weight: var(--font-weight-bold);
    letter-spacing: var(--letter-spacing-sm);
    text-transform: uppercase;
  }
}

/*
  Colors

  <div class="sg-color-blocks">
    <div class="sg-color-block sg-color-block--beige"><code class="language-css">var(--color-beige)</code></div>
    <div class="sg-color-block sg-color-block--brown"><code class="language-css">var(--color-brown)</code></div>
    <div class="sg-color-block sg-color-block--brown-light"><code class="language-css">var(--color-brown-light)</code></div>
    <div class="sg-color-block sg-color-block--gold"><code class="language-css">var(--color-gold)</code></div>
    <div class="sg-color-block sg-color-block--gold-light"><code class="language-css">var(--color-gold-light)</code></div>
    <div class="sg-color-block sg-color-block--off-white"><code class="language-css">var(--color-off-white)</code></div>
    <div class="sg-color-block sg-color-block--orange"><code class="language-css">var(--color-orange)</code></div>
  </div>

  Styleguide Settings.Colors
*/

/* These styles are used by the style guide example */
.sg-color-block {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: var(--spacing-sm);
  }

  &::after {
    border-radius: var(--border-radius-default);
    box-shadow: var(--box-shadow-default);
    content: "";
    display: block;
    height: var(--spacing-lg);
    width: 50%;
  }
}

@each $color in ( beige, brown, brown-light, gold, gold-light, off-white, orange ) {
  .sg-color-block--$(color) {
    &::after {
      background: var(--color-$(color));
    }
  }
}
