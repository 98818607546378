/* ==========================================================================
 * Label
 * ========================================================================== */

/*
  Label

  Markup:
  <span class="c-label">Label</span>

  Styleguide: Components.Label
*/

.c-label {
  @apply --heading-sm;
  color: var(--theme-color-text-muted);
}
