/* ==========================================================================
 * Blockquotes
 * ========================================================================== */

/*
  Blockquotes

  Markup:
  <blockquote class="c-blockquote">
    <p class="c-blockquote__text">This is the content of the blockquote. This is the content of the blockquote. This is the content of the blockquote. This is the content of the blockquote. This is the content of the blockquote. </p>
    <cite class="c-blockquote__cite">
      Person who said it<br>
      Who is qualified by this line
    </cite>
  </blockquote>

  Styleguide: Components.Blockquotes
*/

.c-blockquote {
  margin: 0;

  &__text {
    margin-bottom: 0;
  }

  &__cite {
    text-align: right;
    display: block;

    &::before {
      content: "-";
    }
  }
}
