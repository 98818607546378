/* ==========================================================================
 * Accordions
 * ========================================================================== */

/*
  Accordions

  Markup:
  <div data-expand="~ .js-collapse">
    Accordion Link
  </div>
  <div class="js-collapse">
    Accordion Content
  </div>

  Styleguide: Components.Accordions
*/

.js-accordion {
  overflow: hidden;
  max-height: 0;
  transition: max-height 450ms, opacity 350ms;
  opacity: 0;

  &.js-opened {
    max-height: 1000px;
    opacity: 1;
  }

  .js-accordion-toggle {
    margin-bottom: var(--spacing-med);
  }
}

[data-expand] {
  cursor: pointer;
}

.js-accordion-toggle {
  border-bottom: var(--border-default);
  padding-top: 0;
  padding-bottom: var(--spacing-sm);
  margin-bottom: var(--spacing-sm);
  position: relative;

  &:not(:first-child) {
    margin-top: var(--spacing-med);
  }

  &::after {
    display: block;
    content: "";
    height: 0.7em;
    width: 1.5em;
    top: 0.6em;
    right: 0;
    position: absolute;
    background-image: svg-load("../../assets/svg/down-arrow-dark.svg");
    background-size: 100% auto;
    background-position: right center;
    transition: transform 300ms;
    transform: rotate(0deg);
  }

  &.js-toggled-on::after {
    transform: rotate(180deg);
  }
}
