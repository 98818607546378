/* ==========================================================================
 * Header
 * ========================================================================== */

/*
  Header

  Markup:
  <header class="l-header">
    <div class="l-header__container">
      <a class="c-logo"></a>
      <nav class="c-navigation">
        <a href="#">Link</a>
        <a href="#">Link</a>
        <a href="#">Link</a>
        <a href="#">Link</a>
        <a href="#">Link</a>
      </nav>
    </div>
  </header>

  Styleguide: Layout.Header
*/

:root {
  --active-line-height: 3px;
}

.l-header {
  padding-bottom: var(--spacing-sm);
  padding-top: var(--spacing-sm);
  width: 100%;
  position: relative;
  z-index: 110;

  &__container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--screen-width-max);
    padding-left: var(--spacing-med);
    padding-right: var(--spacing-med);
    width: 100%;
    position: relative;
  }

  &__floating-container {
    transition: opacity 0.3s ease-out;
    display: none;

    &--pin-right {
      left: auto;
      right: var(--spacing-med);
      top: -200%;
      opacity: 0;
    }

    &--pin-right.in {
      opacity: 1;
      top: calc(var(--spacing-med) / 2);
      right: var(--spacing-med);
    }

    .c-form {
      width: 80%;
    }

    .c-hamburger {
      color: var(--theme-color-accent);
      padding: var(--spacing-med) calc(var(--spacing-med) * 2);
    }

    @media screen and (min-width: 720px) {
      display: flex;
      flex-direction: row-reverse;
      width: calc(100% - 15rem);
      position: absolute;
      z-index: 10;
    }
  }

  .c-logo {
    &--alternate {
      background-size: 100% auto;
      background-position: 0 20%;
    }

    @media screen and (min-width: 720px) {
      margin-right: var(--spacing-med);
    }
  }

  .c-navigation {
    display: none;

    a[href] {
      display: block;
      white-space: nowrap;

      @media screen and (min-width: 1200px) {
        font-size: 101%;
      }

      &::after {
        content: "";
        position: relative;
        display: block;
        height: var(--active-line-height);
        width: 0;
        left: 50%;
        transition: background-color 250ms ease-out,
          left 250ms ease-out,
          width 250ms ease-out;
        background-color: rgba(255, 255, 255, 0);
      }
    }

    a[href]:hover::after,
    a[href]:focus::after,
    .menu-item.active > a::after {
      background-color: var(--theme-color-accent);
      width: 100%;
      left: 0%;
    }

    > .c-link:not(:last-child),
    > .menu-item:not(:last-child) {
      @media screen and (min-width: 720px) {
        margin-right: var(--spacing-sm);
      }

      @media screen and (min-width: 1000px) {
        margin-right: var(--spacing-med);
      }
    }

    .sub-menu a {
      display: inline-block;
    }

    .menu-contact > a,
    .menu-portal-login > a {
      border: 1px solid var(--theme-color-accent);
      padding-left: var(--spacing-med);
      padding-right: var(--spacing-med);
      padding-top: calc(var(--active-line-height) * 2);
      margin-top: -calc(var(--active-line-height) * 2);
      border-radius: var(--active-line-height);

      &::after {
        visibility: hidden;
      }
    }

    @media screen and (min-width: 720px) {
      display: flex;
    }
  }
}

.has-mobile-menu {
  .l-header {
    position: fixed;
    z-index: 110;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
}

.js-show-searchbar .l-header .c-navigation {
  opacity: 0;
}
