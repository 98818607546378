/* ==========================================================================
 * Checkradios
 * ========================================================================== */

/*
  Checkradios

  Markup:
  <form class="c-form">
    <fieldset class="c-form__fieldset">
      <legend class="c-form__legend">Radio Fieldset</legend>
      <label class="c-checkradio">
        <input type="radio" name="radio-buttons" value="radio-1">
        <div class="c-checkradio__bubble"></div>
        <div class="c-label">Radio 1</div>
      </label>
      <label class="c-checkradio">
        <input type="radio" name="radio-buttons" value="radio-2">
        <div class="c-checkradio__bubble"></div>
        <div class="c-label">Radio 2</div>
      </label>
      <label class="c-checkradio">
        <input type="radio" name="radio-buttons" value="radio-3">
        <div class="c-checkradio__bubble"></div>
        <div class="c-label">Radio 3</div>
      </label>
    </fieldset>
    <fieldset class="c-form__fieldset">
      <legend class="c-form__legend">Checkbox Fieldset</legend>
      <label class="c-checkradio">
        <input type="checkbox" name="checkboxes-buttons" value="check-1" checked>
        <div class="c-checkradio__bubble"></div>
        <div class="c-label">Checkbox 1</div>
      </label>
      <label class="c-checkradio">
        <input type="checkbox" name="checkboxes-buttons" value="check-1">
        <div class="c-checkradio__bubble"></div>
        <div class="c-label">Checkbox 2</div>
      </label>
      <label class="c-checkradio">
        <input type="checkbox" name="checkboxes-buttons" value="check-1">
        <div class="c-checkradio__bubble"></div>
        <div class="c-label">Checkbox 3</div>
      </label>
    </fieldset>
  </form>

  Styleguide: Components.Checkradios
*/

.c-checkradio {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: var(--spacing-sm);
  position: relative;
  transition: color 0.2s ease-in-out;
  user-select: none;

  &:hover {
    color: var(--theme-color-accent);
  }

  &__bubble {
    background-color: var(--theme-color-input);
    border-radius: var(--border-radius-default);
    box-shadow: 0 0 0 2px var(--color-gold-light) inset;
    display: block;
    flex-shrink: 0;
    height: 20PX;
    margin-right: var(--spacing-sm);
    transition-duration: 0.2s;
    transition-property: background-color, box-shadow;
    transition-timing-function: ease-in-out;
    width: 20PX;
  }

  input {
    display: none;

    &:checked {
      + .c-checkradio__bubble {
        box-shadow: 0 0 0 2px var(--theme-color-accent) inset;
      }
    }

    &[type="radio"] {
      + .c-checkradio__bubble {
        border-radius: 50%;
      }

      &:checked {
        + .c-checkradio__bubble {
          background-color: #fff;
          box-shadow: 0 0 0 5px var(--theme-color-accent) inset;
        }
      }
    }

    &[type="checkbox"] {
      &:checked {
        + .c-checkradio__bubble {
          background-color: var(--theme-color-accent);
          background-image: svg-load("../../assets/img/icons/check.svg", fill: #fff);
        }
      }
    }
  }
}
