/* ==========================================================================
 * Body
 * ========================================================================== */

html,
body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  font-size: 14PX;
}

body {
  background-color: var(--background-color-default);
  min-height: 100vh;
  position: relative;
}
